import React, { useState } from "react";
import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
    UserOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import footerLogo from '../../images/Newsletter@2x.png';
import footericon1 from '../../images/Vector@2x.png';

const MyFooter = () => {
    const location = useLocation();
    if (location.pathname === '/toc' || location.pathname === '/privacy') {
        return null;
    };

    return <div className="footer-heart">
        <Divider className="divider"/>
        <div className="center">
            <img src={footerLogo} alt="" />
            <div className="center-center">
                {/* <Link
                    to="/about"
                    target="_blank"
                >
                    <Button className='but-link' type="link">{'关于我们'}</Button>
                </Link>
                <Link
                    to="/toc"
                    target="_blank"
                >
                    <Button className='but-link' type="link">{'服务条款'}</Button>
                </Link>
                <Link
                    to="/privacy"
                    target="_blank"
                >
                    <Button className='but-link' type="link">{'隐私政策'}</Button>
                </Link>
                <Link
                    to="/us_download"
                >
                    <Button className='but-link' type="link">{'联系我们'}</Button>
                </Link> */}
            </div>
            <div className="center-right">
                <div></div>
                {/* <img src={footericon1} alt="" /> */}
            </div>
        </div>
        <div className="bottom">
            <div>©2023 产品版权所有。保留所有权利。</div>
        </div>
    </div>
}

export default MyFooter;