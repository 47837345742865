import React from "react";
import './index.css';
import {
    Slider,
    Form,
    Tooltip
} from 'antd';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';

const SliderInput = (props) => {
    const {
        title,
        name,
        min = 0,
        max = 100,
        step = 1,
        iconTitle = '',
        disabled = false
    } = props;

    return (<div className="slider-input">
       <div className="slider-input-title">
            <span>{title}</span>
            <Tooltip placement="top" title={iconTitle} arrow={true}>
                <QuestionCircleOutlined />
            </Tooltip>
        </div>
        <Form.Item
            name={name}
            noStyle
        >
            <Slider
                className="slider-input-item"
                min={min}
                max={max}
                step={step}
                disabled={disabled}
            />
        </Form.Item>
    </div>
    )
};
export default SliderInput;