import React from 'react';
import './index.css';
import { Button, Checkbox, Form, Input } from 'antd'

const ContentTwo = () => {
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-two'>
            <div className='input-area'>
                <div className='input-area-title'>联系我们</div>
                <div className='input-area-content'>如果您有任何问题或疑问，请随时与我们联系。</div>
            </div>
            <div className='input-form'>
                <Form
                    name="basic"
                    initialValues={{
                        remember: false,
                    }}
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='form'
                >
                    <Form.Item
                        label={<div style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '16px' }}>姓名</div>}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的姓名',
                            },
                        ]}
                        className='input-name'
                    >
                        <Input placeholder="请输入您的姓名"/>
                    </Form.Item>

                    <Form.Item
                        label={<div style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '16px' }}>邮箱</div>}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的邮箱',
                            },
                        ]}
                        className='input-email'
                    >
                        <Input placeholder="请输入您的邮箱" />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        className='input-checked'
                    >
                        <Checkbox>同意协议</Checkbox>
                    </Form.Item>

                    <Form.Item className='input-but-item'>
                        <Button type="primary" htmlType="submit" className='input-but'>
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ContentTwo;