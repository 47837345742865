import React from 'react';
import './index.css';
import backgroundImage from '../../../images/us&downloadImage.png'
import { Button } from 'antd';
import {
    AppleOutlined,
    WindowsOutlined,
    AndroidFilled,
    MacCommandOutlined,
} from '@ant-design/icons';

const ContentOne = () => {
    return (
        <div className='us-content-one'>
            <div className='bg'>
                <img src={backgroundImage} alt="" className='background-img'/>
                <div className='background'></div>
            </div>
            <div className='word'>
                <div className='text'>
                    <div className='text-title'>解决方案</div>
                    <div className='text-content'>这是一个下载页面，您可以在这里下载我们的产品</div>
                </div>
                <div className='buts'>
                    <div className='box'>
                        <AppleOutlined className='icon'/>
                        <span className='span'>产品1</span>
                        <Button type="primary" className='but'>下载</Button>
                    </div>
                    <div className='box'>
                        <WindowsOutlined className='icon'/>
                        <span className='span'>产品2</span>
                        <Button type="primary" className='but'>下载</Button>
                    </div>
                    <div className='box'>
                        <AndroidFilled className='icon'/>
                        <span className='span'>产品3</span>
                        <Button type="primary" className='but'>下载</Button>
                    </div>
                    <div className='box'>
                        <MacCommandOutlined className='icon'/>
                        <span className='span'>产品4</span>
                        <Button type="primary" className='but'>下载</Button>
                    </div>
                </div>
            </div>
            <div className='content-footer'></div>
        </div>
    );
};

export default ContentOne;