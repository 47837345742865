import React, { useEffect, useRef } from 'react';
import {
    Button,
    Image
} from 'antd';
import './index.css';
import img1 from '../../../images/img1.jpg';
import img2 from '../../../images/img2.jpg';
import img3 from '../../../images/img3.jpg';
import img4 from '../../../images/img4.jpg';
import img5 from '../../../images/img5.jpg';
import img6 from '../../../images/img6.jpg';
import img7 from '../../../images/img7.jpg';
import img8 from '../../../images/img8.jpg';
import img9 from '../../../images/img9.jpg';
import img10 from '../../../images/img10.jpg';
import img11 from '../../../images/img11.jpg';
import img12 from '../../../images/img12.jpg';
import img13 from '../../../images/img13.jpg';
import img14 from '../../../images/img14.jpg';
import img15 from '../../../images/img15.jpg';
import img16 from '../../../images/img16.jpg';

const listOne = [
    {
        id: 1,
        img: img1,
        title: '风景',
        content: '我们提供创新的产品设计解决方案，帮助客户实现业务增长和成功',
        links: [
            {
                buttext: '创新',
                url: 'http://www.baidu.com'
            },
            {
                buttext: '设计',
                url: 'http://www.google.com'
            },
            {
                buttext: '解决方案',
                url: 'http://www.github.com'
            }
        ]
    },
    {
        id: 3,
        img: img3,
        title: '油画',
        content: '我们提供创意和专业的平面设计服务，满足客户的各种设计需求',
        links: [
            {
                buttext: '创意',
                url: 'http://www.baidu.com'
            },
            {
                buttext: '专业',
                url: 'http://www.google.com'
            },
            {
                buttext: '设计服务',
                url: 'http://www.github.com'
            }
        ]
    },
    {
        id: 5,
        img: img5
    },
    {
        id: 7,
        img: img7
    },
    {
        id: 9,
        img: img9
    },
    {
        id: 11,
        img: img11
    },
    {
        id: 13,
        img: img13
    },
    {
        id: 15,
        img: img15
    }
];
const listTwo = [
    {
        id: 2,
        img: img2
    },
    {
        id: 4,
        img: img4
    },
    {
        id: 6,
        img: img6
    },
    {
        id: 8,
        img: img8
    },
    {
        id: 10,
        img: img10
    },
    {
        id: 12,
        img: img12
    },
    {
        id: 14,
        img: img14
    },
    {
        id: 16,
        img: img16
    },
];

const ContentThree = () => {
    const containerRef = useRef(null);
  
    const isElementInViewport = (element) => {
        var rect = element.getBoundingClientRect();
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return rect.top <= windowHeight;
    }
    const handleScroll = () => {
        if (containerRef.current) {
            const container = containerRef.current;
            const elements = container.getElementsByClassName('anime');
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (isElementInViewport(element) && !element.classList.contains('animate')) {
                    element.classList.add('animate');
                } else if (!isElementInViewport(element) && element.classList.contains('animate')) {
                    element.classList.remove('animate');
                }
            }
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='contentThree'>
            <div className='type-area' ref={containerRef}>
                <div className='title anime'>
                    <div>精选案例</div>
                    <div>以下是我们的一些优秀案例</div>
                </div>
                <div className='content'>
                    <Image.PreviewGroup
                        preview={{
                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                        }}
                    >
                        <div className='content-box-one' >
                            {
                                listOne.map((item) => (<div key={item.id} className='card anime'>
                                    <Image src={item.img} alt="" />
                                    {/* <div className='card-title'>{item.title}</div>
                                    <div className='card-content'>{item.content}</div>
                                    <div className='card-buts'>
                                        {
                                            item.links.map((but, index) => <Button className='but' type="text" key={index}>{but.buttext}</Button>)
                                        }
                                    </div> */}
                                </div>))
                            }
                        </div>
                        <div className='content-box-two' >
                            {
                                listTwo.map((item) => (<div key={item.id} className='card anime'>
                                    <Image src={item.img} alt=""/>
                                    {/* <div className='card-title'>{item.title}</div>
                                    <div className='card-content'>{item.content}</div>
                                    <div className='card-buts'>
                                        {
                                            item.links.map((but, index) => <Button className='but' type="text" key={index}>{but.buttext}</Button>)
                                        }
                                    </div> */}
                                </div>))
                            }
                        </div>
                    </Image.PreviewGroup>
                </div>
            </div>
        </div>
    );
};

export default ContentThree;