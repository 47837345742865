import React, { useRef, useEffect } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip,
    Switch
} from 'antd';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    RollbackOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { SuperfractionRepairAPI } from '../../api/request.js'
import { useNavigate } from 'react-router-dom';

const getBase64 = (img, callback) => {
    try {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    } catch (error) {}
};

// const isWidthAndHeight = (file) => {
//     return new Promise((resolve) => {
//         try {
//             const reader = new FileReader();
//             reader.onload = function (e) {
//                 const img = document.createElement('img');

//                 img.onload = function () {
//                     const width = img.width;
//                     const height = img.height;
//                     // console.log('宽度:', width);
//                     // console.log('高度:', height);
//                     if (width < 4500 && height < 4500) {
//                         resolve(true);
//                     } else {
//                         message.error('图片的最大宽度和最大高度只能上传小于4500像素');
//                         resolve(false);
//                     }
//                 };

//                 img.src = e.target.result;
//             };
//             reader.readAsDataURL(file);
//         } catch (error) {
//             resolve(false);
//         }
//     })
// };

const SuperfractionRepair = () => {
    const [imageUrl, setImageUrl] = React.useState('');

    const [getImageUrl, setGetImageUrl] = React.useState('');
    const [disabledSubmit, setDisabledSubmit] = React.useState(false);

    const formRef = useRef(null);
    const navigate = useNavigate();

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload = async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('您只能上传图片文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图像必须小于2MB!');
                return false;
            }
            // const isResolution = await isWidthAndHeight(file);

            return isLt2M && isJpgOrPng;
            // return isLt2M && isJpgOrPng && isResolution;
        } catch (error) {
            message.error('出错了，可以稍后刷新再试!');
            return false;
        }
    };
    const handleChange = (info) => {
        if (info?.fileList?.length > 0) {
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
        }
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };
    const handleFormSubmit = async (values) => {
        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const formData = new FormData();
            formData.append('sourceImage', values.source[0].originFileObj);
            formData.append('needRestore', Number(values.need_restore));

            const res = await SuperfractionRepairAPI(formData);
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('生成失败，请重试');
            };
        } catch (error) {
            message.error('生成失败，请重试');
        };
        setDisabledSubmit(false);
    };
    const handleDownload = () => {
        const imageUrl = getImageUrl[0];

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<div className="superfraction-repair">
        <div className="superfraction-repair-header">
            <Button shape="circle" icon={<RollbackOutlined />} onClick={() => navigate('/home')}/>
            <div>Playground</div>
        </div>
        <div className="superfraction-repair-box">
            <div className="prompt">
                <Form
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                    ref={formRef}
                    layout="inline"
                    initialValues={{need_restore: false}}
                >
                    <div className="title">
                        <span>Prompt</span>
                        <Tooltip placement="top" title={'上传图片'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <Form.Item
                        name="source"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                        rules={[{ required: true, message: '请选择图片' }]}
                    >
                        <Upload.Dragger
                            name="source"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            showUploadList={{
                                showDownloadIcon: true,
                                downloadIcon: 'Download',
                                showRemoveIcon: true,
                                removeIcon: <DeleteOutlined onClick={(e) => { console.log(e, 'custom removeIcon event'); setImageUrl(''); }} />,
                            }}
                            className='upload-source'
                            disabled={disabledSubmit}
                        >
                            {imageUrl ? (
                                <img src={imageUrl} alt="avatar" />
                            ) : (<>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-hint">单击或拖动文件到此区域</p>
                            </>
                        )}
                        </Upload.Dragger>
                    </Form.Item>
                    <div className="hint">
                        <div>source Image</div>
                    </div>
                    <div className="title">
                        <span>Forced Repair</span>
                        <Tooltip placement="top" title={'强制修复（一般不要开启，除非图片太模糊。）'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <Form.Item
                        name="need_restore"
                        noStyle
                        valuePropName="checked"
                    >
                        <Switch disabled={disabledSubmit}/>
                    </Form.Item>
                </Form>
              
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    Generate
                </Button>
            </div>
            <div className="output-image">
                <div className="output-image-bfc">
                    { getImageUrl ? <Image src={getImageUrl} className="img"/> : <></> }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >正在生成中，请稍等...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl ?
                        <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
                            <Button
                                onClick={handleDownload}
                                className="but"
                                icon={<DownloadOutlined />}
                            ></Button>
                        </Tooltip> : <></>
                }
            </div>
        </div>
    </div>
    )
};
export default SuperfractionRepair;