import React from 'react';
import { StarFilled } from '@ant-design/icons';
import './index.css';
import {
    Button,
    Rate
} from 'antd';
import apivideoimage from '../../../images/apivideoimage.png'

const ApiContentOne = () => {
    return (
        <div className='api-content-one'>
            <div className='api-content-one-left'>
                <div>API产品介绍</div>
                <div>我们提供丰富的 API 产品，满足您的各类需求，从数据分析到人工智能，帮助您构建强大的应用程序和服务。</div>
                <div className='introduction'>
                    <div>API产品介绍</div>
                    <div className='list'>
                        <div><StarFilled />强大的数据分析能力</div>
                        <div><StarFilled />全面的文档和支持</div>
                        <div><StarFilled />灵活的可定制性</div>
                        <div><StarFilled />安全可靠的服务</div>
                        <div><StarFilled />高效的性能和稳定性</div>
                        <div><StarFilled />简单易用的接口</div>
                    </div>
                </div>
            </div>
            <div className='api-content-one-right'>
                <img src={apivideoimage} alt="" />
                <div>
                    <div>￥免费试用</div>
                    <div></div>
                    <div className='rate-box'>
                        <Rate className='rate' allowHalf defaultValue={2.5} style={{ fontSize: 16 }} />
                        <div>立即体验</div>
                    </div>
                </div>
                <Button className='but'>了解更多</Button>
            </div>
        </div>
    );
};

export default ApiContentOne;