import React from 'react';
import './index.css';
import image1 from '../../../images/imageContent1.png';
import image2 from '../../../images/imageContent2.png';
import image3 from '../../../images/imageContent3.png';
import image4 from '../../../images/imageContent4.png';
import image5 from '../../../images/imageContent5.png';
import image6 from '../../../images/imageContent6.png';
import { useNavigate } from 'react-router-dom';

const ContentTwo = () => {
    const navigate = useNavigate();

    const handleButtonClick = (props) => {
        if (props === 1) {
            navigate('/face_change');
        } else if (props === 2) {
            navigate('/text_style_image');
        } else if (props === 3) {
            navigate('/text_to_image');
        } else if (props === 4) {
            navigate('/portrait_master');
        } else if (props === 5) {
            navigate('/superfraction_repair');
        } else if (props === 6) {
            navigate('/image_to_image');
        } else {
            navigate('/text_style_image');
        }
    };

    return (
        <div className='contentTwo'>
            <div className='card' onClick={() => handleButtonClick(1)}>
                <img src={image1} alt=""/>
                <div className='card-content'>
                    <div></div>
                    <div>
                        <div className='card-title'>AI换脸</div>
                        <div className='card-text'></div>
                    </div>
                </div>
            </div>
            <div className='card' onClick={() => handleButtonClick(2)}>
                <img src={image2} alt=""/>
                <div className='card-content'>
                    <div></div>
                    <div>
                        <div className='card-title'>风格化文生图</div>
                        <div className='card-text'></div>
                    </div>
                </div>
            </div>
            {/* <div className='card' onClick={() => handleButtonClick(3)}>
                <img src={image3} alt=""/>
                <div className='card-content'>
                    <div></div>
                    <div>
                        <div className='card-title'>aaaaaaaaaaaa</div>
                        <div className='card-text'>ccccccccccccccccccccccccccccccccccccccccc</div>
                    </div>
                </div>
            </div>
            <div className='card' onClick={() => handleButtonClick(4)}>
                <img src={image4} alt=""/>
                <div className='card-content'>
                    <div></div>
                    <div>
                        <div className='card-title'>aaaaaaaaaaaa</div>
                        <div className='card-text'>ccccccccccccccccccccccccccccccccccccccccc</div>
                    </div>
                </div>
            </div> */}
            <div className='card' onClick={() => handleButtonClick(5)}>
                <img src={image5} alt=""/>
                <div className='card-content'>
                    <div></div>
                    <div>
                        <div className='card-title'>超分</div>
                        <div className='card-text'></div>
                    </div>
                </div>
            </div>
            <div className='card' onClick={() => handleButtonClick(6)}>
                <img src={image6} alt=""/>
                <div className='card-content'>
                    <div></div>
                    <div>
                        <div className='card-title'>风格化图生图</div>
                        <div className='card-text'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentTwo;