import React from 'react';
import './index.css';
import apiimage1 from '../../../images/apiimage1.png'
import apiimage2 from '../../../images/apiimage2.png'
import apiimage3 from '../../../images/apiimage3.png'
import { Button } from 'antd';

const ApiContentThree = () => {
    return (
        <div className='api-content-three'>
            <div className='images'>
                <img src={apiimage1} alt="" />
                <img src={apiimage2} alt="" />
                <img src={apiimage3} alt="" />
            </div>
            <div className='tensor'>
                <div className='left'>tensors</div>
                <div className='right'>
                    <div className='text'>我们的产品将带你进入科技的未来，体验前所未有的创新和便利。</div>
                    <div className='but'>
                        <Button type="primary">立即体验</Button>
                        <Button>了解更多</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApiContentThree;