import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import ServerApiPage from '../pages/serverApiPage';
import ContactUsAndDownload from '../pages/contactUsAndDownload';
import FaceSwipe from '../pages/faceSwipe';
import TextStyleImage from '../pages/textStyleImage';
import TextToImage from '../pages/textToImage';
import PortraitMaster from '../pages/portraitMaster';
import SuperfractionRepair from '../pages/superfractionRepair';
import ImageToImage from '../pages/imageToImage';
import Toc from '../pages/toc';
import Privacy from '../pages/privacy';

export default function AppRouter() {
    return (
        <Routes>
            {/* <Route path="/" element={<FaceSwipe />} />
            <Route path="/face_change" element={<FaceSwipe />} /> */}

            <Route exact path="/" element={<Home/>} />
            <Route path="/home" element={<Home />} />
            <Route path="/serverApi" element={<ServerApiPage/>} />
            <Route path="/us_download" element={<ContactUsAndDownload />} />

            <Route path="/face_change" element={<FaceSwipe />}/>
            <Route path="/text_style_image" element={<TextStyleImage />}/>
            <Route path="/text_to_image" element={<TextToImage />}/>
            <Route path="/portrait_master" element={<PortraitMaster />}/>
            <Route path="/superfraction_repair" element={<SuperfractionRepair />} />
            <Route path="/image_to_image" element={<ImageToImage />} />

            {/* <Route path="/toc" element={<Toc />} /> */}
            {/* <Route path="/privacy" element={<Privacy />} /> */}
        </Routes>
    );
};