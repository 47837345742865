import axios from 'axios';
//34.125.49.149    广州团队在使用
//34.125.135.253
//35.230.94.81
//34.125.91.207  L4
//10.168.2.225:8010  金哥电脑
//10.168.2.225:8002  金哥电脑
//34.168.156.23
//34.125.100.62   生产地址
//test-server.internal.tensorbee.com
//34.168.10.165
//184.72.54.32
//10.168.2.151
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const FaceSwipeAPI = (formData) => {
    return new Promise((resolve, reject) => {
        axios.post('http://184.72.54.32/v1/faceswap/specifyimage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ak5giHqec7Dtxgph'
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post('http://184.72.54.32/v1/faceswap/infobytaskid', {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ak5giHqec7Dtxgph'
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const TextStyleImageAPI = (param) => {
    return new Promise((resolve, reject) => {
        axios.post('http://35.230.94.81/v1/images/imagegenerate', param, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ak5giHqec7Dtxgph'
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post('http://35.230.94.81/v1/images/infobytaskid', {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ak5giHqec7Dtxgph'
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const TextStyleImageGetStyleNode = () => {
    return new Promise((resolve, reject) => {
        axios.get('http://35.230.94.81/v1/images/getimagestyle', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ak5giHqec7Dtxgph'
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};

export const SuperfractionRepairAPI = (formData) => {
    return new Promise((resolve, reject) => {
        axios.post('http://184.72.54.32/v1/images/imageupscaler', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ak5giHqec7Dtxgph'
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post('http://184.72.54.32/v1/images/infobytaskid', {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ak5giHqec7Dtxgph'
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(data);
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const ImageToImageAPI = (formData) => {
    return new Promise((resolve, reject) => {
        axios.post('http://34.168.10.165/v1/images/imagetoimage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ak5giHqec7Dtxgph'
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post('http://34.168.10.165/v1/images/infobytaskid', {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ak5giHqec7Dtxgph'
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const ImageToImageGetStyleNode = () => {
    return new Promise((resolve, reject) => {
        axios.get('http://34.168.10.165/v1/images/getimagestyle', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ak5giHqec7Dtxgph'
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};