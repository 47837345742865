import React from 'react';
import './index.css';
import {
    AndroidOutlined,
    AppleOutlined,
    YuqueOutlined,
    BulbOutlined,
    MoneyCollectOutlined,
    CloudOutlined
} from '@ant-design/icons';
import { Button } from 'antd';

const ApiContentTwo = () => {
    return (
        <div className='api-content-two'>
            <div className='content-title'>
                <div className='title'>产品介绍</div>
                <div className='cont'>
                    <div>我们的产品提供高效的API服务</div>
                    <div>
                        <div className='text'>
                            <AndroidOutlined className='icon-android'/>
                            <div>支持Android平台</div>
                        </div>
                        <Button type="link">了解更多</Button>
                    </div>
                    <div>
                        <div className='text'>
                            <AppleOutlined className='icon-apple'/>
                            <div>支持iOS平台</div>
                        </div>
                        <Button type="link">了解更多</Button>
                    </div>
                </div>
            </div>
            <div className='content-cont'>
                <div className='title'>Scalable, Affordable and easy to use APls for generative models</div>
                <div className='falls-bfc'>
                    <div className='falls-one'>
                        <div className='box'>
                            <YuqueOutlined className='icon'/>
                            <div className='name'>Speed</div>
                            <div className='cont'>Segmind provides developers access to thefastest Serverless Stable Diffusion APls.</div>
                        </div>
                        <div className='box'>
                            <BulbOutlined className='icon'/>
                            <div className='name'>Ease of Use</div>
                            <div className='cont'>Segmind provides developers access to thefastest Serverless Stable Diffusion APls.</div>
                        </div>
                    </div>
                    <div className='falls-two'>
                        <div className='box'>
                            <MoneyCollectOutlined className='icon'/>
                            <div className='name'>Affordability</div>
                            <div className='cont'>Segmind provides developers access to thefastest Serverless Stable Diffusion APls.Segmind provides developers access to thefastest Serverless Stable Diffusion APls.</div>
                        </div>
                        <div className='box'>
                            <CloudOutlined className='icon'/>
                            <div className='name'>Cloud</div>
                            <div className='cont'>Segmind provides developers access to thefastest Serverless Stable Diffusion APls.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApiContentTwo;