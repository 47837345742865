import React from 'react';
import ContentOne from './contentOne';
import ContentTwo from './contentTwo';
import './index.css'

const ContactUsAndDownload = () => {
    return (
        <div className='us-download'>
            <ContentOne/>
            <ContentTwo/>
        </div>
    );
};

export default ContactUsAndDownload;