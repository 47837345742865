import React from 'react';
import './index.css';
import ApiContentOne from './apiContentOne';
import ApiContentTwo from './apiContentTwo';
import ApiContentThree from './apiContentThree';

const ServerApiPage = () => {
    return (
        <div className='server-api-page'>
            <ApiContentOne />
            <ApiContentTwo />
            <ApiContentThree />
        </div>
    );
};

export default ServerApiPage;